
import React from "react"

function MustacheRight(){
    return (
        <svg width="100%" viewBox="0 0 280 129" xmlns="http://www.w3.org/2000/svg">
            <path d="M116.0238 126.9837c44.05 5.661 92.5328-3.459 127.9558-28.0924 17.401-12.0535 32.1521-28.5852 35.2296-47.6257 1.5057-9.3586-.0116-18.9352-4.8257-27.3652-4.8217-8.4428-13.545-15.2197-23.8105-18.9357C240.3254 1.1892 228.8788-.229 217.4783.9564c-11.4193.9957-23.0073 6.4438-29.2295 14.6335-6.4353 8.0175-8.4475 17.8473-6.382 26.6237 1.919 8.8127 7.3566 16.913 15.4991 22.5356.2378.1585.6098.1432.8334-.0457.24-.196.249-.511.02-.696-14.2778-11.935-18.481-32.3075-5.8805-46.2564 5.8971-7.001 15.4132-11.1933 25.5632-11.8771 10.1211-.8013 20.5047.7796 29.3124 4.346 8.8889 3.5515 15.8607 9.4311 19.6745 16.6903 3.7891 7.273 4.8006 15.6213 3.1708 23.6773-3.2393 16.249-16.7447 30.7637-33.0855 41.3489-33.018 21.631-77.8748 28.8291-117.4973 22.592-40.0814-6.292-75.4518-25.2392-101.7482-49.8578-.0549-.0577-.1492-.1474-.2126-.1918-3.6248-3.163-9.8765-3.2003-13.957-.0866C-.5212 67.506-.886 72.5929 2.731 75.7494c29.6368 25.8789 68.8984 45.4764 113.2929 51.2343" fill="currentColor" fillRule="evenodd"/>
        </svg>
    );
}

export default MustacheRight