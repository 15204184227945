
import React from "react"

function MustacheLeft(){
    return (
        <svg width="100%" viewBox="0 0 274 126" xmlns="http://www.w3.org/2000/svg">
            <path d="M79.4186 63.8277c.2086.2063.5883.2246.8386.035 8.294-6.0075 14.0738-14.3848 16.4305-23.3221 2.4985-8.9074 1.0513-18.693-4.8268-26.463C86.1934 6.1254 75.104 1.1643 63.9414.6684 52.8152-.0193 41.504 1.9107 31.2509 6.1554c-10.2674 4.186-19.209 11.379-24.395 20.073C1.6782 34.909-.3093 44.5957.6583 53.9306c1.9983 18.9909 15.5893 34.9465 32.0216 46.2866 33.438 23.1816 80.5353 30.203 124.091 22.5714 43.8673-7.7434 83.4555-29.163 113.942-56.4676.0648-.0606.1623-.1548.2272-.2155 3.5872-3.4199 3.296-8.5028-.6716-11.3292-3.9674-2.8397-10.086-2.3554-13.6895 1.078-27.1242 25.8909-62.8582 46.4854-102.5287 54.575-39.241 8.0148-82.8745 2.764-114.129-17.5076C24.4667 83.0095 11.99 69.0252 9.683 52.8453c-1.1671-8.0205.266-16.4512 4.3755-23.9244 4.133-7.4605 11.2917-13.6745 20.207-17.6344 8.8284-3.9713 19.1209-6.018 29.004-5.6598 9.9257.2389 19.0409 4.0302 24.4531 10.8027 11.6188 13.4559 6.3916 34.1064-8.2552 46.7258-.2185.1823-.2497.4792-.0487.6725" fill="currentColor" fillRule="evenodd"/>
        </svg>
    );
}

export default MustacheLeft